import { template } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { service } from "@ember/service";
import AdminHeader from "./admin-header";
import ApiSection from "./api-section";
import FilterNoResults from "./filter-no-results";
export default class SidebarApiSections extends Component {
    @service
    sidebarState;
    get sections() {
        if (this.sidebarState.combinedMode) {
            return this.sidebarState.panels.filter((panel1)=>!panel1.hidden).flatMap((panel1)=>panel1.sections);
        } else {
            return this.sidebarState.currentPanel.sections;
        }
    }
    static{
        template(`
    <AdminHeader />

    {{#each this.sections as |sectionConfig|}}
      <ApiSection
        @sectionConfig={{sectionConfig}}
        @collapsable={{@collapsable}}
      />
    {{/each}}

    <FilterNoResults />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
